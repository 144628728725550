/* Skills */

.skills .title {
	text-align: center;
}

.skill-button-container {
	margin: 1.5em 0;
	text-align: center;
}

.skillbutton {
	box-shadow: none;
	outline: none
}

.skillbutton-active {
	box-shadow: inset 0 0 0 1px _palette(accent);
	color: _palette(accent) !important;
}

.skill-row-container {
	width: 100%;
	overflow: hidden;
	text-align: center;
}

// .skillbar {
// 	position:relative;
// 	// display:block;
// 	margin-bottom:15px;
// 	// width:100%;
// 	// background:#eee;
// 	height:35px;
// 	// border-radius:3px;
// 	// -moz-border-radius:3px;
// 	// -webkit-border-radius:3px;
// 	// -webkit-transition:0.4s linear;
// 	// -moz-transition:0.4s linear;
// 	// -ms-transition:0.4s linear;
// 	// -o-transition:0.4s linear;
// 	// transition:0.4s linear;
// 	// -webkit-transition-property:width, background-color;
// 	// -moz-transition-property:width, background-color;
// 	// -ms-transition-property:width, background-color;
// 	// -o-transition-property:width, background-color;
// 	// transition-property:width, background-color;
// }

.skillbar {
	margin: 0.3em;
	// float: left;
	position:relative;
	display:inline-block;
	box-sizing:border-box;
	// margin-bottom:15px;
	background:#eee;
	height:35px;
	border-radius:3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	-webkit-transition:0.4s linear;
	-moz-transition:0.4s linear;
	-ms-transition:0.4s linear;
	-o-transition:0.4s linear;
	transition:0.4s linear;
	-webkit-transition-property:width, background-color;
	-moz-transition-property:width, background-color;
	-ms-transition-property:width, background-color;
	-o-transition-property:width, background-color;
	transition-property:width, background-color;
}

.skillbar-title {
	// position:absolute;
	// display: table-cell;
	top:0;
	left:0;
	font-weight:bold;
	font-size:13px;
	color:#fff;
	background:#6adcfa;
	-webkit-border-top-left-radius:3px;
	-webkit-border-bottom-left-radius:4px;
	-moz-border-radius-topleft:3px;
	-moz-border-radius-bottomleft:3px;
	border-top-left-radius:3px;
	border-bottom-left-radius:3px;
}

.skillbar-title span {
	display:block;
	background:rgba(0, 0, 0, 0.1);
	padding:0 20px;
	height:35px;
	line-height:35px;
	-webkit-border-top-left-radius:3px;
	-webkit-border-bottom-left-radius:3px;
	-moz-border-radius-topleft:3px;
	-moz-border-radius-bottomleft:3px;
	border-top-left-radius:3px;
	border-bottom-left-radius:3px;
}

.skillbar-bar {
	height:35px;
	width:0px;
	background:#6adcfa;
	border-radius:3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
}

.skill-bar-percent {
	position:absolute;
	right:10px;
	top:0;
	font-size:11px;
	height:35px;
	line-height:35px;
	color:#444;
	color:rgba(0, 0, 0, 0.4);
}
